import { useState } from 'react';
import './DemoApp.css';

const sources = [
  {
    id: 1,
    source: "https://playground.powerbi.com/sampleReportEmbed",
    title: "Sample Report Demo",
  },
  {
    id: 2,
    source: "https://app.powerbi.com/reportEmbed?reportId=e1488832-c627-49e4-b73e-3ed21806cd81&autoAuth=true&embeddedDemo=true",
    title: "AssetAllocSample",
  },
  {
    id: 3,
    source: "https://app.powerbi.com/reportEmbed?reportId=2203f473-91ad-488c-ab10-2df94033bb6b&autoAuth=true&embeddedDemo=true",
    title: "Another Sample",
  }
];


const PowerBIPOC = (props) => {
  const {height, width} = props || {};
  const [currentReport, setCurrentReport] = useState('');

  const handleOnChange = (e) => {
    setCurrentReport(JSON.parse(e.target.value));
  };


  return (
    <section className='power-bi-i-frame'>
      <div className={"select-source"}>
        <label htmlFor={"report-select"}>Choose a Power Bi Report:</label>
        <select name={"reports"} id={"report-select"} value={currentReport} onChange={e => handleOnChange(e)} >
          <option value={''}>Please Choose a Report</option>
          { sources && sources.length > 0 &&
            sources.map((report) => (
              <option key={report.id} value={JSON.stringify(report)}>{report.title}</option>
            ))
          }
        </select>
      </div>

      { currentReport &&
        <iframe
          allowFullScreen={true}
          height={height}
          src={currentReport.source}
          title={currentReport.title}
          width={width}
        >
        </iframe>
      }
    </section>
  );
};

export default PowerBIPOC;
