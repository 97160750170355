import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CollapseIcon from '../../assets/icons/collapse.svg';
import CustomToolTip from './ToolTip';
import DownloadIcon from '../../assets/icons/download.svg';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import PauseIcon from '../../assets/icons/pause.svg';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Select from '@mui/material/Select';
import ToggleThumbIcon from '../../assets/icons/toogleThumb.svg';
import Toolbar from '@mui/material/Toolbar';
import Zoom from '@mui/material/Zoom';


const Header = (props) => {
  const { autoPlay, currentIndex, currentItemNumber, handleClose, handleSelectOnChange, imageData, mediaItem, toggleAutoPlay, toggleThumbSwiper, totalItems } = props || {};

  return (
    <AppBar sx={{ backgroundColor: "#213863", boxShadow: "none", padding: "16px 0", position: 'relative' }}>
      <Toolbar className={"header-toolbar-wrapper"}>
        <Box className={"header-toolbar"}>
          <FormControl fullWidth sx={{ color: "#ffffff" }} size="small">
            <InputLabel sx={{ color: "#ffffff" }} id="media-carousel-select">Media Selection</InputLabel>
            <Select
              labelId="media-carousel-select"
              id="media-carousel-select-box"
              value={mediaItem}
              sx={{
                color: "white",
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '.MuiSvgIcon-root ': {
                  fill: "white !important",
                }
              }}
              label={"Media Selection"}
              onChange={handleSelectOnChange}
            >
            { imageData && imageData.length > 0 && imageData.map((item, index) => (
              <MenuItem key={`(${index + 1}/${imageData.length}) ${item?.title}`} value={item?.title} data-count={index + 1}>{item?.title}</MenuItem>
            ))}
            </Select>
          </FormControl>
        </Box>
        <div className={"btn-wrapper"}>
          <p className={"header-count"}>({currentItemNumber}/{totalItems})</p>
          <CustomToolTip
            enterDelay={250}
            slots={{ transition: Zoom }}
            title={"Download Current Image"}
          >
            <IconButton
              aria-label={"download current image"}
              className={"dialog-btn-close nav-buttons"}
              color={"#ffffff"}
              download
              edge={"end"}
              href={imageData[currentIndex]?.src}
            >
              <img alt={'collapse'} src={DownloadIcon} />
            </IconButton>
          </CustomToolTip>
          <CustomToolTip
            enterDelay={250}
            slots={{ transition: Zoom }}
            title={"Show / Hide Thumbnails"}
          >
            <IconButton
              aria-label={"remove thumbnails"}
              className={"dialog-btn-close nav-buttons"}
              color={"#ffffff"}
              edge={"end"}
              onClick={toggleThumbSwiper}
            >
              <img alt={'collapse'} src={ToggleThumbIcon} />
            </IconButton>
          </CustomToolTip>
          <CustomToolTip
            enterDelay={250}
            slots={{ transition: Zoom }}
            title={autoPlay ? "Pause Carousel" : " Play Carousel"}
          >
            <IconButton
              aria-label={autoPlay ? "pause carousel" : " play carousel"}
              className={"dialog-btn-close nav-buttons"}
              color={"#ffffff"}
              edge={"end"}
              onClick={toggleAutoPlay}
            >
              { autoPlay
                ? <img alt={'collapse'} src={PauseIcon} />
                : <PlayArrowIcon fontSize="large" className={"white-icon"} />
              }
            </IconButton>
          </CustomToolTip>
        </div>
        <IconButton
          aria-label={"close popup"}
          className={"dialog-btn-close"}
          color={"#ffffff"}
          edge={"end"}
          onClick={handleClose}
        >
          <p className={"dialog-btn-close-text"}>Collapse</p>
          <img alt={'collapse'} src={CollapseIcon} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
