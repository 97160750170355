import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Keyboard, FreeMode, Pagination, Navigation } from 'swiper/modules';
import { mediaCarouselDummyDataLocal } from '../../dummyData';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./carousel.css";


const CarouselMini = (props) => {
  const [searchParams] = useSearchParams();
  const slideCount = searchParams.get("slideCount") ? parseInt(searchParams.get("slideCount")) : 4;
  const [ imageData, setImageData ] = useState(null);

  const ignoreList = [
    "react-devtools-backend-manager",
    "react-devtools-content-script",
    "react-devtools-bridge",
    "webpackHotUpdate"
  ];

  useEffect(() => {
    if (!props.data) {
      // setImageData(mediaCarouselDummyData);
      applyListener();
    } else {
      setImageData(props.data);
      console.log('we have props.data', props.data)
    };
    return () => {  }
  }, [props.data]);

  const applyListener = () => {
    if (window) {
      // if (window.parent) { window.parent.postMessage('READY', "*"); };
      window.addEventListener("message", messageHandler);
      console.log("post message listener attached");
    };
  };

  const messageHandler = (event) => {
    // console.log('event is happening', event)
    if (event.data?.hello || event.data?.type === 'webpackWarnings' || ignoreList.includes(event?.data?.source)) return;
    if (event.data === 'READY') return;
    console.log('post message event.data', event.data);
    if (event?.data) {
      try {
        const parsedData = JSON.parse(event.data);
        console.log('dummy', parsedData);
        setImageData(parsedData);
      } catch (error) {
        console.log('err', event, error);
      };
    };
  };

  const fakePostMessage = () => {
    setImageData(mediaCarouselDummyDataLocal);
    // if (window?.parent) {
    //   window.parent.postMessage(JSON.stringify(mediaCarouselDummyData), "*");
    //   if (window.parent?.['0']?.name === 'FrameContainer2') {
    //     setImageData(mediaCarouselDummyData);
    //   };
    // };
  };


  return (
    <div className={'carousel-mini-wrapper'}>
    {imageData && imageData.length > 0
      ? <Swiper
          a11y={true}
          className={"mini-carousel"}
          keyboard={{
            enabled: true,
          }}
          loop={true}
          slidesPerView={slideCount}
          pagination={{
            clickable: true,
          }}
          modules={[A11y, Keyboard, FreeMode, Pagination, Navigation]}
          navigation={true}
          watchSlidesProgress={true}
        >
        {imageData && imageData.length > 0 && imageData.map((item, index) => (
          <SwiperSlide key={`slide-${index}-${item.id}`}>
            <figure className={"figure"}>
              <img src={item.src} alt={item.alt} title={`(${index + 1}/${imageData.length}) ${item?.title}`} />
              <figcaption className={"figcaption-top"}>{`(${index + 1}/${imageData.length}) ${item?.title}`}</figcaption>
            </figure>
          </SwiperSlide>
        ))}
      </Swiper>
      : <div className={"post-message-btn-wrapper"}>
        <Button className={'post-message-btn'} variant="contained" size="large" onClick={() => { fakePostMessage() }}>Message Parent</Button>
      </div>
    }
    </div>
  );
};

export default CarouselMini;
