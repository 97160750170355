import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const CustomToolTip = styled(({ className, ...props }) => ( <Tooltip {...props} classes={{ popper: className }} /> ))
  (({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#182737',
      color: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      fontSize: 12,
      padding: '10px'
    },
}));

export default CustomToolTip;
