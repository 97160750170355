import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Keyboard, FreeMode, Pagination, Navigation } from 'swiper/modules';
import { mediaCarouselDummyDataLocal } from '../../dummyData';
import PopUp from "./Popup";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import './carousel.css';


const Carousel3 = (props) => {
  const [imageData, setImageData] = useState(null);
  const [searchParams] = useSearchParams();
  const slideCount = searchParams.get("slideCount") ? parseInt(searchParams.get("slideCount")) : 4;


  const ignoreList = [
    "react-devtools-backend-manager",
    "react-devtools-content-script",
    "react-devtools-bridge",
    "webpackHotUpdate"
  ];

  useEffect(() => {
    if (!props.data) {
      applyListener();
    } else {
      setImageData(props.data);
    };
    return () => {  }
  }, [props.data]);

  const applyListener = () => {
    if (window) {
      if (window.parent) {
        window.parent.postMessage('READY', "*");
        const payload = {
          cmpId: window.name,
          height: '300px',
          typeOfOperation: "window_resize",
        };
        window.parent.postMessage(JSON.stringify(payload), "*");
      };
      window.addEventListener("message", messageHandler);
      console.log("REACT post message listener attached");
    };
  };

  const messageHandler = (event) => {
    if (event.data?.hello || event.data?.type === 'webpackWarnings' || ignoreList.includes(event?.data?.source)) return;
    if (event.data === 'READY') return;
    if (event.origin === 'https://localhost:3012') return;
    if (event?.srcElement?.name === 'IframeCollapsibleWrapper') {
      // console.log('REACT good source');
      if (event?.data) {
      try {
        const parsedData = JSON.parse(event.data);
        // console.log('REACT parsed data', parsedData)
        if (parsedData?.typeOfOperation === "initialize-data") {
          // console.log('REACT got image data!', parsedData?.payload);
          setImageData(parsedData?.payload);
        };
      } catch (error) {
        console.log('REACT err parsing data', event, error);
      };
    };
    }

  };

  const messageParent = (payload) => {
    if (window?.parent) {
      window.parent.postMessage(JSON.stringify(payload), "*");
    };
  };

  const fakePostMessage = () => {
    setImageData(mediaCarouselDummyDataLocal);
  };


  return (
    <div className={'carousel-mini-wrapper'}>
    {imageData && imageData.length > 0 && <PopUp imageData={imageData} messageParent={messageParent} /> }
    {imageData && imageData.length > 0
      ? <Swiper
          a11y={true}
          className={"mini-carousel"}
          keyboard={{
            enabled: true,
          }}
          loop={true}
          slidesPerView={slideCount}
          pagination={{
            clickable: true,
          }}
          modules={[A11y, Keyboard, FreeMode, Pagination, Navigation]}
          navigation={true}
          watchSlidesProgress={true}
        >
        {imageData && imageData.length > 0 && imageData.map((item, index) => (
          <SwiperSlide key={`slide-${index}-${item.id}`}>
            <figure className={"figure"}>
              <img src={item.src} alt={item.alt} />
              <figcaption className={"figcaption-center"}>{`(${index + 1}/${imageData.length}) ${item?.title}`}</figcaption>
            </figure>
          </SwiperSlide>
        ))}
      </Swiper>
      : <div className={"post-message-btn-wrapper"}>
        <Button className={'post-message-btn'} variant="contained" size="large" onClick={() => { fakePostMessage() }}>Message Parent</Button>
      </div>
    }
    </div>
  );
};

export default Carousel3;
