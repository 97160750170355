import { useState } from 'react';
import Carousel from './Carousel';
import Dialog from '@mui/material/Dialog';
import ExpandIcon from '../../assets/icons/expand.svg';
import Header from './Header';
import IconButton from '@mui/material/IconButton';


const PopUp = ({ imageData, messageParent }) => {
  const [autoPlay, setAutoplay] = useState(false);
  const [currentItemNumber, setCurrentItemNumber] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaItem, setMediaItem] = useState(imageData?.[0]?.title);
  const [open, setOpen] = useState(false);
  const [showTumbSwiper, setShowThumbSwiper] = useState(true);
  const totalItems = imageData.length;

  const handleSelectOnChange = (event) => {
    setMediaItem(event.target.value);
    const count = imageData.findIndex((item) => item.title === event.target.value);
    setCurrentItemNumber(count + 1);
    setCurrentIndex(count);
  };

  const handleClickOpen = () => {
    setOpen(true);
    const payload = {
      cmpId: window.name,
      typeOfOperation: "full_screen",
    }
    messageParent(payload);
  };

  const handleClose = () => {
    setOpen(false);
    setMediaItem(imageData?.[0]?.title);
    setCurrentItemNumber(1);
    setCurrentIndex(0);
    const payload = {
      cmpId: window.name,
      height: '300px',
      typeOfOperation: "window_resize",
    };
    messageParent(payload);
  };

  const toggleAutoPlay = () => {
    setAutoplay(prev => !prev);
  };

  const toggleThumbSwiper = () => {
    setShowThumbSwiper(prev => !prev);
  }

  const updatePicklistFromSlider = (slideIndex) => {
    setCurrentItemNumber(slideIndex + 1);
    const newItem = imageData[slideIndex].title;
    setMediaItem(newItem);
  };


  return (
    <div className={"carousel-popup"}>
      <IconButton
        aria-label={"open in full screen"}
        className={"dialog-btn"}
        color="inherit"
        edge={"end"}
        onClick={handleClickOpen}
        title={"open in full screen"}
      >
         <img alt={'expand carousel'} src={ExpandIcon} />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <Header
          autoPlay={autoPlay}
          currentIndex={currentIndex}
          currentItemNumber={currentItemNumber}
          handleClose={handleClose}
          handleSelectOnChange={handleSelectOnChange}
          imageData={imageData}
          mediaItem={mediaItem}
          toggleAutoPlay={toggleAutoPlay}
          toggleThumbSwiper={toggleThumbSwiper}
          totalItems={totalItems}
        />
        <Carousel autoPlay={autoPlay} data={imageData} currentIndex={currentIndex} showTumbSwiper={showTumbSwiper} updatePicklistFromSlider={updatePicklistFromSlider} />
      </Dialog>
    </div>
  );
};

export default PopUp;
