const mediaCarouselDummyData = [
  { id: 1, src: "https://swiperjs.com/demos/images/nature-1.jpg", alt: "image", title: "place 1" },
  { id: 2, src: "https://swiperjs.com/demos/images/nature-2.jpg", alt: "image", title: "place 2" },
  { id: 3, src: "https://swiperjs.com/demos/images/nature-3.jpg", alt: "image", title: "place 3" },
  { id: 4, src: "https://swiperjs.com/demos/images/nature-4.jpg", alt: "image", title: "place 4" },
  { id: 5, src: "https://swiperjs.com/demos/images/nature-5.jpg", alt: "image", title: "place 5" },
  { id: 6, src: "https://swiperjs.com/demos/images/nature-6.jpg", alt: "image", title: "place 6" },
  { id: 7, src: "https://swiperjs.com/demos/images/nature-7.jpg", alt: "image", title: "place 7" },
  { id: 8, src: "https://swiperjs.com/demos/images/nature-8.jpg", alt: "image", title: "place 8" },
  { id: 9, src: "https://ifpipepulsedevelopment.file.force.com/sfc/servlet.shepherd/version/renditionDownload?rendition=ORIGINAL_Jpg&versionId=068fJ000000Xyjn&operationContext=CHATTER&contentId=05TfJ000001K6rv", alt: "image", title: "place 9" },
  { id: 10, src: "https://swiperjs.com/demos/images/nature-10.jpg", alt: "image", title: "place 10" }
];

const mediaCarouselDummyDataLocal = [
  { id: 1, src: "../../images/689_HUB2313576-en-brochure-1.pdf_2_1.jpg", alt: "image", title: "689_HUB2313576-en-brochure-1.pdf_2_1" },
  { id: 2, src: "../../images/95_SSP562 CW St James House Sheffield FINAL 002.pdf_13_25.jpg", alt: "image", title: "95_SSP562 CW St James House Sheffield FINAL 002.pdf_13_25" },
  { id: 3, src: "../../images/93_SSP562 CW St James House Sheffield FINAL 002.pdf_7_8.jpg", alt: "image", title: "93_SSP562 CW St James House Sheffield FINAL 002.pdf_7_8" },
  { id: 4, src: "../../images/92_SSP562 CW St James House Sheffield FINAL 002.pdf_2_1.jpg", alt: "image", title: "92_SSP562 CW St James House Sheffield FINAL 002.pdf_2_1" },
  { id: 5, src: "../../images/87_SSP562 CW St James House Sheffield FINAL 002.pdf_8_17.jpg", alt: "image", title: "87_SSP562 CW St James House Sheffield FINAL 002.pdf_8_17" },
  { id: 6, src: "../../images/84_SSP562 CW St James House Sheffield FINAL 002.pdf_7_15.jpg", alt: "image", title: "84_SSP562 CW St James House Sheffield FINAL 002.pdf_7_15" },
  { id: 7, src: "../../images/83_Vicar-Ln-Sheffield-Building-Photo-4-LargeHighDefinition.jpg", alt: "image", title: "83_Vicar-Ln-Sheffield-Building-Photo-4-LargeHighDefinition" },
  { id: 8, src: "../../images/82_Vicar-Ln-Sheffield-Building-Photo-2-LargeHighDefinition.jpg", alt: "image", title: "82_Vicar-Ln-Sheffield-Building-Photo-2-LargeHighDefinition" },
  { id: 9, src: "../../images/80_Vicar-Ln-Sheffield-Primary-Photo-1-LargeHighDefinition.jpg", alt: "image", title: "80_Vicar-Ln-Sheffield-Primary-Photo-1-LargeHighDefinition" },
  { id: 10, src: "https://ifpipepulsedevelopment.file.force.com/sfc/servlet.shepherd/version/renditionDownload?rendition=ORIGINAL_Jpg&versionId=068fJ000000Xyjn&operationContext=CHATTER&contentId=05TfJ000001K6rv", alt: "image will be broken if not viewed in salesforce", title: "image from salesforce" },
];


export { mediaCarouselDummyData, mediaCarouselDummyDataLocal };

