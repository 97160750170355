import { useEffect, useState } from 'react';
import { Card, Button, Paper, Stack, Box, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const ChartsPOC = () => {
  const [chartData, setChartData] = useState();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (window && !isReady) {
      window.addEventListener("message", messageHandler);
      if (window.parent) {
        window.parent.postMessage('READY', "*");
        console.log("fired ready event from React.");
        setIsReady(true);
      }
    }
  }, []);

  const messageHandler = (event) => {
    if (!event.data || event.data?.hello || event.data?.type === 'webpackWarnings' || event.data === 'READY' || event?.data?.source?.includes('react-devtools')) return;
    console.log('event.data', event.data);
    try {
      const parsedData = JSON.parse(event.data);
      console.log(parsedData);
      if (parsedData.typeOfOperation === 'initialize-chart-data') {
        setChartData(parsedData.chartData);
      }
    } catch (e) {
      console.log('error', e);
    };
  };

  return (
    <div style={{ backgroundColor: "white", minHeight: "99vh" }}>
      {chartData ?
        <Radar data={chartData} />
        :
        <div style={{ height: "100vh", display: 'flex', justifyContent: "center", alignItems: "center", margin: "auto", alignContent: "center" }}>
          <Typography>No data available</Typography>
        </div>
      }
    </div>
  );
};

export default ChartsPOC;
