import { useState, useEffect } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import axios from 'axios';
import InvesorFlowIcon from "../../images/investorflow_logo.png";
import PowerBIIcon from "../../images/PowerBI_Icon.png";
import './DemoApp.css';

const sources = [
  {
    id: 1,
    report: "4b72b3f0-b9e6-4815-afca-cad5d6eba40d",
    title: "US Sales Analysis",
		workspace: "a15ca2f4-0d55-466c-8c3e-a7bad02ab9a2",
  },
  {
    id: 2,
    report: "e1488832-c627-49e4-b73e-3ed21806cd81",
    title: "Asset Allocation Sample",
		workspace: "6138aa45-ac38-448e-b049-91305294b669",
  },
  {
    id: 3,
    report: "2203f473-91ad-488c-ab10-2df94033bb6b",
    title: "Sample Report Demo",
		workspace: "6138aa45-ac38-448e-b049-91305294b669",
  }
];


const PowerBiDemoApp = () => {
	const [currentReport, setCurrentReport] = useState('');
	const [report, setReport] = useState();
	const [reportTitle, setReportTitle] = useState();
	const [isEmbedded, setIsEmbedded] = useState(false);
	const [isError, setIsError] = useState(false);
	const [displayMessage, setMessage] = useState(`The report is bootstrapped. Click the Embed Report button to set the access token`);
  const [isFiltersHidden, setIsFiltersHidden] = useState(false);
	const [clientReportConfig, setReportConfig] = useState({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: undefined,
	});
	const [eventHandlersMap, setEventHandlersMap] = useState(new Map([
		['loaded', () => console.log('Report has loaded')],
		['rendered', () => console.log('Report has rendered')],
		['error', (event) => {
				if (event) {
					console.error(event.detail);
				}
			},
		],
		['visualClicked', () => console.log('visual clicked')],
		['pageChanged', (event) => console.log(event)],
	]));

	// CSS Class to be passed to the embedded component
	const reportClass = 'report-container';

	useEffect(() => {
		if (report) {
			report.setComponentTitle('Embedded Report');
		} else if (getQueryStringsFromURL()) {
			embedReport();
		};
	}, [report]);

	const buildControlButtons = () => {
		// <button onClick={ embedReport } className={"embed-report"}>Embed Report</button>
		return (
			isEmbedded ?
			<>
				<div className={"btn-block"}>
					{ selectSource() }
					<button onClick={ changeVisualType }>Change visual type</button>
					<button onClick={ isFiltersHidden ? showFilterPane : hideFilterPane }>{`${isFiltersHidden ? "Show" : "Hide"} filter pane`}</button>
					<button onClick ={ setDataSelectedEvent }>Set event</button>
				</div>
				<label className={"display-message"}>{ displayMessage }</label>
			</>
			:
			<>
				<label className={"display-message position"}>{ displayMessage }</label>
				{ selectSource() }
			</>
		);
	};

	const buildHeader = () => {
		return (
			<div className={"header"}>{reportTitle} Power BI Report</div>
		);
	};

	const buildFooter = () => {
		return (
			<div className={"footer"}>
				<p>Powered By InvestorFlow</p>
				<img title={"InvestorFlow"} alt={"InvesorFlow Icon"} className={"footer-icon"} src={InvesorFlowIcon} />
				<label className={"separator-pipe"}> & </label>
				<p>PowerBI</p>
				<img title={"Power-BI"} alt={"PowerBI Icon"} className={"footer-icon"} src={PowerBIIcon} />
			</div>
		);
	};

	const changeVisualType = async () => {
		// Check if report is available or not
		if (!report) {
			setDisplayMessageAndConsole('Report not available');
			return;
		};

		// Get active page of the report
		const activePage = await report.getActivePage();
		console.log('active page = ', activePage)

		if (!activePage) {
			setMessage('No Active page found');
			return;
		};

		activePage.getVisuals()
		.then(visuals => { console.log('all visuals', visuals) });

		try {
			let pages = await report.getPages();
			console.log('pages', pages)

		} catch (error) {
			console.log('error with pages', error)
		}

		try {
			// Change the visual type using powerbi-report-authoring
			// For more information: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/report-authoring-overview
			const visual = await activePage.getVisualByName('875b419d4b78e8d8a80b');

			console.log('visual', visual)

			const response = await visual.changeType('lineChart');

			setDisplayMessageAndConsole(`The ${visual.type} was updated to lineChart.`);
			return response;
		} catch (error) {
			if (error === 'PowerBIEntityNotFound') {
				console.log('No Visual found with that name');
			} else {
				console.log(error);
			};
		};
	};

  /**
   * Embeds report
   * @returns Promise<void>
  */
	const embedReport = async (data) => {
		const POWER_BI_WORKSPACE_ID = getQueryStringsFromURL()?.POWER_BI_WORKSPACE_ID ? getQueryStringsFromURL().POWER_BI_WORKSPACE_ID : data?.workspace;
		const POWER_BI_REPORT_ID = getQueryStringsFromURL()?.POWER_BI_REPORT_ID ? getQueryStringsFromURL().POWER_BI_REPORT_ID : data?.report;

		if (!POWER_BI_WORKSPACE_ID || !POWER_BI_REPORT_ID) {
			console.log('missing....')
			return setIsError(true);
		};

		try {
			// Get the embed config from the service
			const host = window.location.origin === "https://localhost:3012" ? "https://localhost:5300" : "https://localhost:5300";
			const reportConfigResponse = await axios.get(`${host}/getEmbedToken/${POWER_BI_WORKSPACE_ID}/${POWER_BI_REPORT_ID}`);
			const reportConfig = reportConfigResponse.data;
			console.log('reportConfig', reportConfig)

			if (reportConfigResponse.status !== 200 || reportConfig === null) {
				setIsError(reportConfigResponse.statusText);
				return console.error(`Failed to fetch config for report. Status: ${ reportConfigResponse.status } ${ reportConfigResponse.statusText }`);
			};

			setReportConfig({
				...clientReportConfig,
				embedUrl: reportConfig.embedUrl[0]?.embedUrl,
				accessToken: reportConfig.accessToken
			});

			setReportTitle(reportConfig.embedUrl[0]?.reportName);
			setIsEmbedded(true);
			setMessage('Use the buttons above to interact with the report using Power BI Client APIs.');
		} catch (error) {
			console.log('error fething report', error);
			return setIsError(error?.response?.data?.error);
		};
	};

	const getQueryStringsFromURL = () => {
		const params = new URL(window.location.href).searchParams;
		const workspace = params.get("workspace");
		const report = params.get("report");
		if (!workspace && !report) return console.log('got nothing', workspace, report);
		return ({
			POWER_BI_WORKSPACE_ID: workspace,
			POWER_BI_REPORT_ID: report
		});
	};

	const handleOnChange = (e) => {
    setCurrentReport(e.target.value);
		if (e.target.value !== '') {
			embedReport(JSON.parse(e.target.value));
		} else {
			setDisplayMessageAndConsole('Please select a report to view');
		};
  };

	const hideFilterPane = async () => {
		// Check if report is available or not
		if (!report) {
			setDisplayMessageAndConsole('Report not available');
			return;
		}

		// New settings to hide filter pane
		const settings = {
			panes: {
				filters: {
					expanded: false,
					visible: false,
				},
			},
		};

		try {
			const response = await report.updateSettings(settings);
			// Update display message
			setDisplayMessageAndConsole('Filter pane is hidden.');
      setIsFiltersHidden(true);
			return response;
		} catch (error) {
			console.error(error);
			return;
		};
	};

	const selectSource = () => {
		return (
			<div className={"select-source"}>
				<label className={'hidden'} htmlFor={"report-select"}>Choose a Power Bi Report:</label>
				<select className={"reports-select"} name={"reports-select"} value={currentReport?.title} id={"report-select"} onChange={e => handleOnChange(e)} >
					<option value={''}>Please Choose a Report</option>
					{ sources && sources.length > 0 &&
						sources.map((report) => (
							<option key={report.id} data-info={report} value={JSON.stringify(report)}>{report.title}</option>
						))
					}
				</select>
			</div>
		);
	};

	const setDataSelectedEvent = () => {
		setEventHandlersMap(new Map([
			...eventHandlersMap,
			['dataSelected', (event) => console.log(event)],
		]));

		setMessage('Data Selected event set successfully. Select data to see event in console.');
	};

	const showFilterPane = async () => {
		// Check if report is available or not
		if (!report) {
			setDisplayMessageAndConsole('Report not available');
			return;
		};

		// New settings to hide filter pane
		const settings = {
			panes: {
				filters: {
					expanded: true,
					visible: true,
				},
			},
		};

		try {
			const response = await report.updateSettings(settings);
			// Update display message
			setDisplayMessageAndConsole('Filter pane is not hidden.');
      setIsFiltersHidden(false);
			return response;
		} catch (error) {
			console.error(error);
			return;
		};
	};

	const setDisplayMessageAndConsole = (message) => {
		setMessage(message);
		console.log(message);
	};

	const reportComponent = () => {
		return (
			<PowerBIEmbed
				embedConfig = { clientReportConfig }
				cssClassName = { reportClass }
				getEmbeddedComponent = { (embedObject) => {
					console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
					setReport(embedObject);
				} }
			/>
		);
	};



	return (
		isError
		? <div className={"error"}>
				<h1>Error fetching PowerBi report</h1>
				<p>Please reach out to admin for help</p>
				<code>{isError}</code>
			</div>
		: <div className = "container">
				{ buildHeader() }
				<div className={"controls"}>
					{ buildControlButtons() }
				</div>
				<div className={"report-component"}>
					{ isEmbedded ? reportComponent() : null }
				</div>
				{ buildFooter() }
			</div>
	);
};

export default PowerBiDemoApp;
