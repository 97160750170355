import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay, Keyboard, FreeMode, Pagination, Navigation, Thumbs, Zoom } from 'swiper/modules';
import { mediaCarouselDummyDataLocal } from '../../dummyData';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/zoom';
import './carousel.css';


const Carousel = ({ autoPlay, data, currentIndex, showTumbSwiper, updatePicklistFromSlider }) => {
  const [ imageData, setImageData ] = useState(data);
  const [ thumbsSwiper, setThumbsSwiper ] = useState(null);

  useEffect(() => {
    const dialogEl = document.querySelector('.MuiDialog-container');
    if (dialogEl) {
      const carousels = dialogEl.querySelector('.swiper');
      if (carousels) {
        const swiper = dialogEl?.querySelector('.swiper')?.swiper;
        if (autoPlay) {
          swiper.autoplay.start();
        } else {
          swiper.autoplay.stop();
        };
      };
    };
  }, [autoPlay]);


  useEffect(() => {
    const dialogEl = document.querySelector('.MuiDialog-container');
    if (dialogEl) {
      const carousels = dialogEl.querySelector('.swiper');
      if (carousels) {
        const swiper = dialogEl?.querySelector('.swiper')?.swiper;
        swiper.slideTo(currentIndex, 750);
      };
    };
  }, [currentIndex]);

  useEffect(() => {
    if (!data) {

    } else {
      setImageData(data);
    }
    return () => {  }
  }, [data]);

  const slideUpdate = (swiperCore) => {
    // console.log('activeIndex', swiperCore.activeIndex);
    updatePicklistFromSlider(swiperCore.activeIndex);
  };


  return (
    <div className={'content-wrapper'}>
      <div className={'carousel-wrapper'}>
        <Swiper
          a11y={true}
          delay={100}
          className={"full-size-carousel"}
          data-full={!showTumbSwiper}
          grabCursor={true}
          keyboard={{
            enabled: true,
          }}
          // loop={true}
          modules={[A11y, Autoplay, Keyboard, FreeMode, Pagination, Navigation, Thumbs, Zoom]}
          navigation={true}
          onRealIndexChange={(swiperCore) => { slideUpdate(swiperCore); }}
          pagination={{
            clickable: true,
          }}
          rewind={true}
          spaceBetween={10}
          thumbs={{ swiper: thumbsSwiper }}
          zoom={true}
          zoom-max-ratio="3"
          zoom-min-ratio="1"
        >
          {imageData && imageData.length > 0 && imageData.map((item, index) => (
            <SwiperSlide key={`slide-${index}-${item.id}`}>
              <figure className={"figure"}>
                <div className={"swiper-zoom-container"}>
                  <img src={item.src} alt={item.alt} title={`Double Click to Zoom - use mouse to move image`} />
                </div>
                {/* <figcaption className={"figcaption-top"}>{`(${index + 1}/${imageData.length}) ${item?.title}`}</figcaption> */}
              </figure>
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          a11y={true}
          className={"nav-carousel"}
          data-show={showTumbSwiper}
          freeMode={true}
          grabCursor={true}
          keyboard={{
            enabled: true,
          }}
          loop={true}
          modules={[A11y, Keyboard, FreeMode, Pagination, Navigation, Thumbs]}
          onSwiper={setThumbsSwiper}
          slidesPerView={6}
          spaceBetween={10}
          watchSlidesProgress={true}
        >
          {imageData && imageData.length > 0 && imageData.map((item, index) => (
            <SwiperSlide key={`slide-${index}-${item.id}`}>
                <figure className={"figure"}>
                  <img src={item.src} alt={item.alt} />
                  <figcaption className={"figcaption"}>{`(${index + 1}/${imageData.length}) ${item?.title}`}</figcaption>
              </figure>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Carousel;
